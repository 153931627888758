<template>
  <div class="content-wrapper">
    <div class="work main-content">

      <div class="page-header">
        <h1>Human Resource Allocation Overview</h1>
      </div>

      <div class="chart-wrapper">
        <BarChart :options="chartOptions" />
      </div>

      <div class="work-allocation-table">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Work Name</th>
              <th scope="col">Principal Staff Name</th>
              <th scope="col">Related Members Number</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="work in works">
              <td>{{ work.work_name }}</td>
              <td>{{ work.employee_name }}</td>
              <td>{{ getEmployeeNumbers(work) }}</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ItemFilter from '@/components/ItemFilter.vue';
import BarChart from '@/components/chart/BarChart.vue';
import Common from '@/lib/common';
import Work from '@/lib/work';

export default {
  name: 'HrAllocation',
  components: {
    ItemFilter,
    BarChart,
  },
  data(){
    return {
      works: [],
      loaded: false,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      }
    }
  },
  mounted(){
    this.loadAllWorks();
  },
  methods:{
    formatDateTime(timestamp, format){
      const date = Common.formatDateTime(timestamp, format);
      return date;
    },
    async loadAllWorks(){
      this.loaded = false;
      const loginInfo = Common.getLoginInfo();
      try{
        const works = await Work.loadAllWorks(this.apiUrl, loginInfo);
        this.works = works;

        const chartData = {
          labels: [],
          datasets: [
            {
              label: 'Count of related members',
              backgroundColor: '#343A40',
              data: [],
            }
          ],
        };

        for(let i = 0; i < works.length; i++){
          const work = works[i];
          const employeeNumbers = this.getEmployeeNumbers(work);
          chartData.labels.push(work.work_name);
          chartData.datasets[0].data.push(employeeNumbers);
        }

        this.setCurrentChartData(chartData);
      }catch(err){
        console.log(err);
      }
    },
    getEmployeeNumbers(work){
      const baseNumber = 1;
      const employeeNumber = work['work_members'].length + baseNumber;
      return employeeNumber;
    },
    ...mapActions(['setCurrentChartData']),
  },
  computed: mapState({
   currentChartData: state => state.chart.currentChartData,
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">
.view-toolbars{
  display: flex;
  .fitler{
    flex: 1;
  }
  .el-icon-plus{
    font-size: 1.2em;
  }
}
.work-allocation-table{
  margin-top: 50px;
}
</style>
